'use client';

import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { darkModeState } from '@/jotai';
import { grey } from '@mui/material/colors';
import { ThemeOptions } from '@mui/system';
export const MuiThemeProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [darkMode] = useAtom(darkModeState);
  const themeOption: ThemeOptions = {
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: grey,
      background: {
        paper: 'var(--bg-color)' // Paper의 배경색
      }
    },
    typography: {
      fontFamily: 'inherit'
    },
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: grey[100],
            '&.Mui-checked': {
              color: grey[200]
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: 'var(--text-color) !important'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'var(--text-color) !important',
            backgroundColor: 'var(--focus-bg-color) !important'
          }
        }
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            borderColor: 'var(--border-color)',
            // ToggleButton의 테두리 색상을 --border-color로 지정
            '&.Mui-selected': {
              borderColor: 'var(--border-color)' // 선택된 상태에서도 테두리 유지
            }
          }
        }
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '& svg': {
              color: 'var(--text-color)' // svg 아이콘의 색상을 --text-color로 지정
            },
            borderColor: 'var(--border-color)',
            // ToggleButton의 테두리 색상 지정
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              // 선택된 버튼의 배경색 설정
              '& svg': {
                color: 'var(--text-color)' // 선택된 상태에서도 svg의 색상 유지
              }
            }
          }
        }
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: 'var(--text-color)' // DialogContentText의 색상을 --text-color로 설정
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          body1: {
            color: 'var(--text-color)' // Typography의 body1 변형의 색상을 --text-color로 설정
          }
        }
      },
      MuiIconButton: {
        // Add style overrides for IconButton
        styleOverrides: {
          root: {
            color: 'var(--text-color)'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // MuiPaper의 root 슬롯 오버라이드
            color: 'var(--text-color)'
          }
        }
      }
    }
  };

  // @ts-ignore
  const theme = createTheme(themeOption);
  useEffect(() => {
    if (darkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.removeAttribute('data-theme');
    }
  }, [darkMode]);
  return <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="MuiThemeProvider" data-sentry-source-file="MuiThemeProvider.tsx">{children}</ThemeProvider>;
};