import { Database } from '@/database.types';
import { createBrowserClient } from '@supabase/ssr';
// @ts-ignore

let debug = false;
if (typeof window !== 'undefined') {
    debug = localStorage.getItem('debug')?.includes('auth') || false;
}
export function createClient() {
    return createBrowserClient<Database>(
        process.env.NEXT_PUBLIC_SUPABASE_URL!,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
        {
            auth: {
                debug: process.env.NEXT_PUBLIC_SUPABASE_AUTH_DEBUG_ENABELED
                    ? process.env.NEXT_PUBLIC_SUPABASE_AUTH_DEBUG_ENABELED === 'true'
                    : false,
            },
        }
    );
}

export const fetchUserId = async () => {
    const supabase = createClient();
    try {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) {
            throw new Error(`Session error: ${sessionError.message}`);
        }
        if (!sessionData.session) {
            throw new Error('No active session found');
        }
        if (!sessionData.session.user) {
            throw new Error('No user associated with the session');
        }
        const userId = sessionData.session.user.id;
        return userId;
    } catch (error) {
        throw error;
    }
};
